import React from "react";
import ContactMainDescription from "./ContactMainDescription/ContactMainDescription";

const ContactMain = () => {
  return (
    <div className="__sweven_contact-main">
      <ContactMainDescription />
    </div>
  );
};

export default ContactMain;
