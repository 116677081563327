import React from "react";
import HomeMainContent from "./MainContent/HomeMainContent";

const HomeMain = () => {
  return (
    <div className="__sweven_home-main">
      <HomeMainContent />
    </div>
  );
};

export default HomeMain;
