import React from "react";
import ProductsMainContent from "./MainContent/ProductsMainContent";

const ProductsMain = () => {
  return (
    <div className="__sweven_products-main">
      <ProductsMainContent />
    </div>
  );
};

export default ProductsMain;
